import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
  },
}))

const PrivacyPolicyPage = ({ data }) => {
  const classes = useStyles()
  const {
    datoCmsPrivacyPage: { sections = [] },
  } = data
  return (
    <Layout bgColor="#B136FF">
      <Seo title="Polityka prywatności" />
      <Box className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          {sections.map(({ id, title, description }) => (
            <Box key={id} mb={5}>
              <Typography gutterBottom component="p" variant="h1">
                {title}
              </Typography>
              <Typography
                variant="body1"
                component="article"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          ))}
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyPageQuery {
    datoCmsPrivacyPage {
      sections {
        id
        title
        description
      }
    }
  }
`

export default PrivacyPolicyPage
